<template>
  <div class="body">
    <div class="from">
      <p>员工登录</p>
      <van-form @submit="onSubmit">
        <van-field
          v-model="user.mobile"
          name="mobile"
          placeholder="请输入手机号"
          :rules="[
            {
              required: true,
              message: '手机格式错误',
              pattern: /^1[345678]\d{9}$/,
            },
          ]"
        />
        <van-field
          v-model="user.pass"
          type="password"
          name="code"
          placeholder="请输入密码"
        />
        <van-button
          :loading="loading"
          :disabled="loading"
          loading-text="登录中..."
          round
          block
          native-type="submit"
          >登录</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import { settoken, setid, settype, setname, setLabelId } from "/src/api/token.js";
import { setuid } from "/src/api/userdata.js";
import { Notify } from "vant";
import { loginapi } from "../../api/index";
export default {
  data() {
    return {
      user: {
        mobile: '',
        pass: "",
        // mobile: 15732060581,
        // pass: "xxw060581",
      },
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      loginapi(this.user).then((res) => {
        if (res.code != 1) {
          return Notify({ type: 'danger', message: res.msg});
        }
        settoken(res.data.token);
        setid(res.data.organ_id);
        settype(res.data.type);
        setname(res.data.name);
        setLabelId(res.data.label_id);
        Notify({ type: 'success', message: '登录成功'});
        setuid(res.data.uid);
        this.$router.push({
          name: "index",
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
  .body{
    width: 100vw;
    height: 100vh;
    position: relative;
    background: url(./src/img/loginh.png) center/cover no-repeat;
    .from{
      width: 3.8rem;
      height: fit-content;
      background: rgba(75, 75, 75, 0.26);
      border-radius: .13rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: .35rem .23rem;
      color: #fff;
      text-align: center;
      box-sizing: border-box;
      p{
        font-size: .18rem;
        margin: 0 0 .27rem;
      }
      .van-cell {
        min-height: .4rem;
        border-radius: .06rem;
        border: 1px solid white;
        background: none;
        margin-bottom: .15rem;
        line-height: .2rem;
        font-size: .14rem;
        box-sizing: border-box;
        &::after{
          display: none;
        }
        /deep/ input{
          border: none;
          background: none;
          color: #fff;
        }
        /deep/ .van-field__error-message{
          font-size: .1rem;
        }
      }
      .van-button--round{
        height: .4rem;
        background: #F14947;
        border-radius: .06rem;
        border: none;
        color: #fff;
        font-size: .16rem;
      }
    }
  }
  @media only screen and (orientation: landscape) {
    .body{
      background-image: url(./src/img/loginbjs.png);
    }
  }
</style>
